import { Step, StepLabel } from "@mui/material"
import {
  StepperWrapper,
  StepperText,
  StyledStepperIcon,
  StyledStepper,
  BoldStepperText,
} from "./styles"
import { ReactNode } from "react"

type StepperProps = {
  activeStep: number
  steps: string[]
  stepperContent: ReactNode
}

// Define the props type for CustomStepIcon
interface CustomStepIconProps {
  active: boolean
  completed: boolean
  text: number
}

const CustomStepIcon = (props: CustomStepIconProps & { active?: boolean }) => {
  const { active, completed, text } = props

  return (
    <StyledStepperIcon completed={completed} active={active ?? false}>
      {completed ? <span>{text}</span> : <span>{text}</span>}
    </StyledStepperIcon>
  )
}

const Stepper = ({ activeStep, steps, stepperContent }: StepperProps) => {
  const visibleSteps = steps
  const visibleActiveStep = activeStep
  return (
    <StepperWrapper>
      <StyledStepper
        activeStep={visibleActiveStep}
        style={{ width: "100%" }}
        alternativeLabel
      >
        {visibleSteps.map((label, index) => {
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          const stepProps: { completed?: boolean } = {
            completed: index < activeStep,
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                StepIconComponent={(props) => (
                  <CustomStepIcon
                    {...props}
                    text={index + 1}
                    active={props.active ?? false}
                    completed={index < activeStep}
                  />
                )}
                {...labelProps}
              >
                {activeStep === index ? (
                  <BoldStepperText>{label}</BoldStepperText>
                ) : (
                  <StepperText>{label}</StepperText>
                )}
              </StepLabel>
            </Step>
          )
        })}
      </StyledStepper>
      {stepperContent}
    </StepperWrapper>
  )
}

export default Stepper
