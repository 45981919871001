import { Routes, Route, Navigate } from "react-router-dom"
import {
  ROOT,
  CENTERS,
  BOOKING,
  BOOKING_SUCCESS,
  TERMS_AND_CONDITIONS,
  PRIVACY_POLICY,
  REFUND_POLICY,
} from "../constants/path"
import Centers from "../containers/Centers"
import Booking from "../containers/Booking"
import BookingSuccess from "../containers/Booking/BookingSuccess"
import NotFound from "../components/NotFound/NotFound"
import TermsAndConditions from "../containers/TermsAndConditions"
import PrivacyPolicy from "../containers/PrivacyPolicy"
import RefundPolicy from "../containers/RefundPolicy"

const AppRoutes = () => (
  <Routes>
    <Route path={ROOT} element={<Navigate to={CENTERS} />} />
    <Route path={CENTERS} element={<Centers />} />
    <Route path={BOOKING} element={<Booking />} />
    <Route path={BOOKING_SUCCESS} element={<BookingSuccess />} />
    <Route path={TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
    <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
    <Route path={REFUND_POLICY} element={<RefundPolicy />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default AppRoutes
