import StaticPageHero from "../../components/StaticPageHero/StaticPageHero"
import { ListContainer, Wrapper } from "./styles"

const RefundPolicy = () => {
  return (
    <Wrapper>
      <StaticPageHero text="Refund Policy" />
      <ListContainer>
        <p>
          <strong>Effective Date:</strong> 18th October, 2024
        </p>

        <ol>
          <li>
            <strong>No Refunds</strong>
            <p>
              All payments made under this Agreement are{" "}
              <strong>irrevocable</strong>, <strong>non-refundable</strong>, and{" "}
              <strong>non-creditable</strong>. Once a payment is processed, it
              cannot be refunded under any circumstances.
            </p>
          </li>
          <li>
            <strong>Know Your Customer (KYC) Compliance</strong>
            <ul>
              <li>
                <strong>Service Provision:</strong> Services will be provided
                only after the successful completion of the Know Your Customer
                (KYC) process and verification of the customer's identity.
              </li>
              <li>
                <strong>Customer Responsibility:</strong> It is the customer's
                responsibility to complete the KYC process within the specified
                timeframe provided by BHIVE Workspace.
              </li>
            </ul>
          </li>
          <br />
          <li>
            <strong>Failure to Complete KYC</strong>
            <p>
              In the event that the customer fails to complete the KYC process
              within the specified timeframe:
            </p>
            <ul>
              <li>
                BHIVE Workspace reserves the right to{" "}
                <strong>cancel the customer's request for services</strong>.
              </li>
              <li>
                The customer will <strong>not be entitled to any refund</strong>{" "}
                of payments already made.
              </li>
              <li>
                No services will be provided until the KYC process is completed.
              </li>
            </ul>
          </li>
          <br />

          <li>
            <strong>Non-Provision of Services</strong>
            <p>
              BHIVE Workspace is not obligated to provide any services until all
              prerequisites, including but not limited to KYC verification, are
              fulfilled by the customer.
            </p>
          </li>
          <li>
            <strong>Acceptance of Terms</strong>
            <p>
              By making a payment to BHIVE Workspace, the customer acknowledges
              and agrees to the terms outlined in this Refund Policy.
            </p>
          </li>
          <li>
            <strong>Changes to the Refund Policy</strong>
            <p>
              BHIVE Workspace reserves the right to modify or update this Refund
              Policy at any time without prior notice. Changes will be effective
              immediately upon posting on our website.
            </p>
          </li>
          <li>
            <strong>Governing Law</strong>
            <p>
              This Refund Policy is governed by the laws of India. Any disputes
              arising from this policy shall be subject to the exclusive
              jurisdiction of the courts located in Bangalore, Karnataka.
            </p>
          </li>
          <li>
            <strong>Contact Information</strong>
            <p>
              If you have any questions or concerns regarding this Refund
              Policy, please contact us:
            </p>
            <ul>
              <li>
                <strong>Email:</strong>{" "}
                <a href="mailto:sales@bhiveworkspace.com">
                  sales@bhiveworkspace.com
                </a>
              </li>
              <li>
                <strong>Phone:</strong> +91 95386 77774
              </li>
              <li>
                <strong>Address:</strong> BHIVE Workspace (Office Space &amp;
                Coworking), L-148, 5th Main Rd, Sector 6, HSR Layout, Bengaluru,
                Karnataka 560102
              </li>
            </ul>
          </li>
        </ol>

        <hr />

        <p>
          <em>
            By proceeding with the payment, you confirm that you have read,
            understood, and agree to abide by this Refund Policy.
          </em>
        </p>
      </ListContainer>
    </Wrapper>
  )
}

export default RefundPolicy
