import { Stack } from "@mui/material"
import StaticPageHero from "../../components/StaticPageHero/StaticPageHero"
import { StrongText, StyledGreyParagraph, Wrapper } from "./styles"
import theme from "../../theme"

const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <StaticPageHero text="Privacy Policy" />
      <Stack flexDirection="column" gap="20px" padding="20px" m="20px auto">
        <StyledGreyParagraph>
          <StrongText>
            This Privacy Policy applies to the BHIVE Workspace, a unit of Tusker
            Workspace Pvt. Ltd
          </StrongText>
        </StyledGreyParagraph>
        <StyledGreyParagraph>
          BHIVE Workspace, a unit of Tusker Workspace Pvt. Ltd recognises the
          importance of maintaining your privacy. We value your privacy and
          appreciate your trust in us. This Policy describes how we treat user
          information we collect on{" "}
          <StrongText>www.bhiveworkspace.com</StrongText> and other offline
          sources. This Privacy Policy applies to current and former visitors to
          our website and to our online customers. By visiting and/or using our
          website, you agree to this Privacy Policy.
        </StyledGreyParagraph>
        <StyledGreyParagraph>
          BHIVE Workspace, a unit of Tusker Workspace Pvt. Ltd is a property of
          BHIVE Workspace, a unit of Tusker Workspace Pvt. Ltd, an Indian
          Company registered under the Companies Act, 2013 having its registered
          office at,{" "}
          <StrongText>
            No. 269, 18th D Main, 6th Block, Koramangala, Bangalore, Karnataka –
            560095.
          </StrongText>
        </StyledGreyParagraph>
        <StrongText>INFORMATION WE COLLECT</StrongText>
        <StyledGreyParagraph>
          <StrongText>Contact information.</StrongText> We might collect your
          Name, Email, Phone, Address at your will.
        </StyledGreyParagraph>
        <StyledGreyParagraph>
          <StrongText>WE COLLECT INFORMATION IN DIFFERENT WAYS.</StrongText>
        </StyledGreyParagraph>
        <StyledGreyParagraph>
          <StrongText> We collect information directly from you.</StrongText> We
          collect information directly from you when you contact us. We also
          collect information if you post a comment on our websites or ask us a
          question through phone or email.
        </StyledGreyParagraph>
        <StrongText>USE OF YOUR PERSONAL INFORMATION</StrongText>
        <StyledGreyParagraph>
          <StrongText>
            We use information to respond to your requests or questions.
          </StrongText>{" "}
          We might use your information to confirm your registration for an
          event or contest.
        </StyledGreyParagraph>
        <StyledGreyParagraph>
          <StrongText>
            We use information to improve our products and services.
          </StrongText>{" "}
          We might use your information to customize your experience with us.
          This could include displaying content based upon your preferences.
        </StyledGreyParagraph>
        <StyledGreyParagraph>
          <StrongText>
            We use information to look at site trends and customer interests.
          </StrongText>{" "}
          We may use your information to make our website and products better.
          We may combine information we get from you with information about you
          we get from third parties.
        </StyledGreyParagraph>
        <StyledGreyParagraph>
          <a href="https://bhiveworkspace.com/">bhiveworkspace.com</a> and
          third-party vendors, including Google, use first-party cookies (such
          as the Google Analytics cookie) and third-party cookies (such as the
          DoubleClick cookie) together to inform, optimize, and deliver better
          experience based on someone’s past visits to our website.
        </StyledGreyParagraph>
        <StyledGreyParagraph>
          <StrongText>We use information for security purposes.</StrongText> We
          may use information to protect our company, our customers, or our
          websites. We use information as otherwise permitted by law.
        </StyledGreyParagraph>
        <StrongText>Disclaimer</StrongText>
        <StyledGreyParagraph>
          The materials on BHIVE Workspace, a unit of Tusker Workspace Pvt.
          Ltd’s site are given “as is”. BHIVE Workspace, a unit of Tusker
          Workspace Pvt. Ltd makes no guarantees, communicated or suggested, and
          thus renounces and nullifies every single other warranties, including
          without impediment, inferred guarantees or states of merchantability,
          fitness for a specific reason, or non-encroachment of licensed
          property or other infringement of rights. Further, BHIVE Workspace, a
          unit of Tusker Workspace Pvt. Ltd does not warrant or make any
          representations concerning the precision, likely results, or
          unwavering quality of the utilization of the materials on its Internet
          site or generally identifying with such materials or on any
          destinations connected to this website.
        </StyledGreyParagraph>
        <StrongText>Constraints</StrongText>
        <StyledGreyParagraph>
          In no occasion should BHIVE Workspace, a unit of Tusker Workspace Pvt.
          Ltd or its suppliers subject for any harms (counting, without
          constraint, harms for loss of information or benefit, or because of
          business interference,) emerging out of the utilization or
          powerlessness to utilize the materials on BHIVE Workspace, a unit of
          Tusker Workspace Pvt. Ltd’s Internet webpage, regardless of the
          possibility that BHIVE Workspace, a unit of Tusker Workspace Pvt. Ltd
          or a BHIVE Workspace, a unit of Tusker Workspace Pvt. Ltd approved
          agent has been told orally or in written of the likelihood of such
          harm. Since a few purviews don’t permit constraints on inferred
          guarantees, or impediments of obligation for weighty or coincidental
          harms, these confinements may not make a difference to you.
        </StyledGreyParagraph>
        <StrongText>Amendments and Errata</StrongText>
        <StyledGreyParagraph>
          The materials showing up on BHIVE Workspace, a unit of Tusker
          Workspace Pvt. Ltd’s site could incorporate typographical, or
          photographic mistakes. BHIVE Workspace, a unit of Tusker Workspace
          Pvt. Ltd does not warrant that any of the materials on its site are
          exact, finished, or current. BHIVE Workspace, a unit of Tusker
          Workspace Pvt. Ltd may roll out improvements to the materials
          contained on its site whenever without notification. BHIVE Workspace,
          a unit of Tusker Workspace Pvt. Ltd does not, then again, make any
          dedication to update the materials.
        </StyledGreyParagraph>
        <StrongText>Links</StrongText>
        <StyledGreyParagraph>
          BHIVE Workspace, a unit of Tusker Workspace Pvt. Ltd has not checked
          on the majority of the websites or links connected to its website and
          is not in charge of the substance of any such connected webpage. The
          incorporation of any connection does not infer support by BHIVE
          Workspace, a unit of Tusker Workspace Pvt. Ltd of the site.
          Utilization of any such connected site is at the user’s own risk.
        </StyledGreyParagraph>
        <StrongText>Governing Law</StrongText>
        <StyledGreyParagraph>
          Any case identifying with BHIVE Workspace, a unit of Tusker Workspace
          Pvt. Ltd’s site should be administered by the laws of the country of{" "}
          <StrongText>India</StrongText> BHIVE Workspace, a unit of Tusker
          Workspace Pvt. Ltd State without respect to its contention of law
          provisions.
        </StyledGreyParagraph>
        <div>
          <StrongText>EMAIL OPT-OUT</StrongText>
          <StyledGreyParagraph>
            <StrongText>
              You can opt out of receiving our marketing emails.
            </StrongText>{" "}
            To stop receiving our promotional emails, please email
            sales@bhiveworkspace.com It may take about ten days to process your
            request. Even if you opt out of getting marketing messages, we will
            still be sending you transactional messages through email and SMS
            about your purchases.
          </StyledGreyParagraph>
        </div>
        <div>
          <StrongText>GRIEVANCE OFFICER</StrongText>
          <StyledGreyParagraph>
            In accordance with Information Technology Act 2000 and rules made
            there under, the name and contact details of the Grievance Officer
            are provided below:
          </StyledGreyParagraph>
        </div>
        <StyledGreyParagraph>Mr. Manohar</StyledGreyParagraph>
        <StyledGreyParagraph>
          BHIVE Workspace (Office Space & Coworking),
        </StyledGreyParagraph>
        <StyledGreyParagraph>
          L-148, 5th Main Rd, Sector 6, HSR Layout,
        </StyledGreyParagraph>
        <StyledGreyParagraph>Bengaluru, Karnataka 560102</StyledGreyParagraph>
        <StyledGreyParagraph>
          Email:{" "}
          <a
            style={{
              color: `${theme.palette.primary.dark}`,
            }}
            href="mailto:it@bhiveworkspace.com"
          >
            it@bhiveworkspace.com
          </a>
        </StyledGreyParagraph>
        <StyledGreyParagraph>Phone : +91 95386 77774</StyledGreyParagraph>
        <StyledGreyParagraph>
          If you have any questions about this Policy or other privacy concerns,
          you can also email us at{" "}
          <a
            style={{
              color: `${theme.palette.primary.dark}`,
            }}
            href="mailto:it@bhiveworkspace.com"
          >
            it@bhiveworkspace.com
          </a>
        </StyledGreyParagraph>
        <div>
          <StrongText>UPDATES TO THIS POLICY</StrongText>
          <StyledGreyParagraph>
            This Privacy Policy was last updated on 9th March, 2016. From time
            to time we may change our privacy practices. We will notify you of
            any material changes to this policy as required by law. We will also
            post an updated copy on our website. Please check our site
            periodically for updates.
          </StyledGreyParagraph>
        </div>
        <div>
          <StrongText>JURISDICTION</StrongText>
          <StyledGreyParagraph>
            If you choose to visit the website, your visit and any dispute over
            privacy is subject to this Policy and the website’s terms of use. In
            addition to the foregoing, any disputes arising under this Policy
            shall be governed by the laws of India.
          </StyledGreyParagraph>
        </div>
      </Stack>
    </Wrapper>
  )
}

export default PrivacyPolicy
