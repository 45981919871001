import { styled } from "@mui/material"
import theme from "../../theme"

export const ErrorModalContainer = styled("div")`
  padding: 16px;
`
export const ErrorModalIconContainer = styled("div")`
  margin-bottom: 12px;
`
export const ErrorModalHeader = styled("div")`
  font-weight: 600;
  font-size: 16px;
`
export const ErrorModalDescription = styled("div")`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 16px 0px;
`
export const ErrorModalActionButtonContainer = styled("div")`
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  gap: 12px;

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    margin-top: 24px;
  }
`
