import { Stack, styled } from "@mui/material"
import theme from "../../theme"

export const FixedButtonContainer = styled(Stack)`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${theme.palette.background.paper};
  padding: 10px 0;
  border-top: 0.5px solid ${theme.palette.neutrals[4]};
`

export const TermsContainer = styled(Stack)`
  overflow-y: auto;
  margin-bottom: 50px;
`

export const ButtonWrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
  padding: 0 8px;
`

export const CheckBoxWrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px;
`
