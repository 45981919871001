import { StyledText, Wrapper } from "./styles"
import Logo from "../../assets/logo.png"

type StaticPageHeroProps = {
  text: string
}

const StaticPageHero = ({ text }: StaticPageHeroProps) => {
  return (
    <Wrapper>
      <img src={Logo} alt="logo" height={142} style={{ marginTop: "30px" }} />
      <StyledText variant="h3">{text}</StyledText>
    </Wrapper>
  )
}

export default StaticPageHero
