import { Stack } from "@mui/material"
import StaticPageHero from "../../components/StaticPageHero/StaticPageHero"
import {
  BoldSpan,
  TypographyHeader,
  TypographyParagraph,
  TypographyPointer,
  Wrapper,
} from "./styles"
import theme from "../../theme"
import { PRIVACY_POLICY } from "../../constants/path"

interface ITermsAndConditionsProps {
  removeImage?: boolean
}

const TermsAndConditions = ({ removeImage }: ITermsAndConditionsProps) => {
  return (
    <Wrapper>
      {!removeImage && <StaticPageHero text="Terms and Conditions" />}
      <Stack flexDirection="column" alignItems="center">
        <TypographyHeader
          variant="h4"
          color={theme.palette.neutrals[6]}
          style={{ marginTop: "30px" }}
          fontWeight={500}
        >
          Terms and Conditions - BHIVE Workspace
        </TypographyHeader>
      </Stack>

      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        <BoldSpan>Effective Date</BoldSpan>: 25th Oct 2024
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={800}
      >
        Introduction
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        Welcome to BHIVE Workspace, a unit of Tusker Workspace Pvt. Ltd (“BHIVE
        Workspace”, “BHIVE”, “we” “us” or “our”). Upon entering our website or
        using the App and applying to use any of the services and/or membership
        (“Services”), you (“User”) agree to comply with these Terms and
        Conditions (“Terms of Use" or “Terms”) and other policies on the
        Website. This document is published in accordance with the provisions of
        Rule 3(1) of the Information Technology (Intermediaries guidelines)
        Rules, 2011 that require publishing the rules and regulations, privacy
        policy and Terms of Use for access or usage of this mobile application
        (“App”) and the (“Site”).
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        ACCESSING OR OTHERWISE USING THE APP/SITE INDICATES USER’S AGREEMENT TO
        ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO THE USER SHALL
        READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={800}
      >
        1. Definitions:
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        <TypographyPointer>
          a. <BoldSpan>Work Space Solutions</BoldSpan> means shared work space,
          co-working workspace, office solutions, personal workstations, meeting
          rooms, conference halls etc. in the form of ready to move in and/or
          use office spaces, desks, cubicles with provisions of basic office
          equipment, furniture, infrastructure, essential services, security
          etc. under various formats, on a daily, weekly and monthly basis which
          may be used by single user subject to the other terms and conditions.
        </TypographyPointer>
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        <TypographyPointer>
          b. <BoldSpan>User</BoldSpan> shall mean any person including a
          corporation/ company/ partnership and/or any legal entity who/which
          chooses to book and use the Work Space Solution and/or any services,
          amenities, products etc. offered by BHIVE only through BHIVE’s mobile
          App / BHIVE Website and accepts all the all the terms and conditions
          including this Terms of Use.
        </TypographyPointer>
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={800}
      >
        2. Scope and Use of Services
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        BHIVE offers to provide Work Space Solutions as defined below, as per
        availability and as per request of the User at the listed locations
        subject to acceptance of the terms and conditions of the Terms of Use
        and upon submitting details as required by BHIVE and/or applicable laws.
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        BHIVE offers the following facilities and Services on an exclusive basis
        to you as follows:
        <br />
        <TypographyPointer>
          a. Day Pass- you may access your chosen BHIVE Workspace location for 1
          day. This Day Pass is valid only on the chosen date and for the chosen
          time specified at purchase.
        </TypographyPointer>
        <br />
        <TypographyPointer>
          b. Bulk Day Pass- you may access your chosen BHIVE Workspace location
          for 10 working days at the cost of 8. This Bulk Day pass is valid for
          30 days starting from the date of purchase.
        </TypographyPointer>
        <br />
        <br />
        You may use the following Services and facilities by availing a day pass
        and performing KYC at our reception desk.
        <br />
        All registrations, membership and passes are subject to availability.
        Use of workspace/facilities/ Services shall not be carry forwarded if
        not used during their term of validity. One usage is counted per day and
        you are required to carry your proof of identity at all times and
        present it to the concerned BHIVE personnel for allowing access to the
        designated centre. The Membership/ Pass facility will be “frozen” if
        BHIVE is unavailable due to government restrictions or force- majeure
        events or any other restriction as per the BHIVEʼs policies, procedures,
        and guidelines <BoldSpan>(“BHIVE Byelaws”)</BoldSpan> and may be
        continued after the restriction are lifted/ force-majeure event ceases
        upon the discretion of BHIVE. You shall not engage in any illegal,
        disruptive or unethical activities in the opinion of BHIVE. BHIVE
        Workspace is accessible during normal business hours in most centers and
        24/7 in a few centers.
        <br />
        <br />
        The User shall be permitted to book through mobile App or Website the
        listed Work Space Solution and shall be permitted to use the Work Space
        Solution upon acceptance of request by BHIVE and upon acceptance of
        terms and conditions of the Terms of Use and other BHIVE Byelaws by the
        User. The tenure for the term of use of Work Space Solutions by User
        shall be for the period/duration/timing approved by BHIVE after
        confirmation of such booking. User shall be obliged to punctually follow
        the stipulations with regard to duration and/or term of use and timings
        of use.
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={800}
      >
        3. Obligations
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        BHIVE may offer the following facilities and services, such as:
        <br />
        <TypographyPointer>
          a. Access to workspace, internet, common areas, and complimentary
          beverages.
        </TypographyPointer>
        <br />
        <TypographyPointer>
          b. Use of meeting rooms, printing facilities, and event spaces may be
          available and could incur extra fees as determined by BHIVE.
        </TypographyPointer>
        <br />
        <TypographyPointer>
          c. Regular upkeep of facilities is provided during business hours.
        </TypographyPointer>
        <br />
        The control of accommodation of the Work Space Solution shall at all
        time vest with BHIVE and shall remain the property of BHIVE. The
        acceptance of the terms and conditions by User for usage of Work Space
        Solution shall not constitute and/or create any lease/sub lease/license
        in favor of User and the same shall be only constituted as availing the
        Work Space Solution services provided by BHIVE.
        <br />
        The User agrees not to misuse any of the facilities and services
        provided by BHIVE including Work Space Solutions for any purpose not
        envisaged by a written agreement with BHIVE. The Company warrants that
        it has full power and authority to carry out the actions contemplated
        under this Agreement and that this Agreement constitutes a legal, valid
        and binding obligation of the Company enforceable against it in
        accordance with its terms.
        <br />
        The User agrees that it shall carry out only lawful and permitted
        activities under this agreement from the space offered to them and shall
        abide by all the laws, rules, regulations applicable to them for
        carrying out permitted business. The User shall not engage in any
        illegal business activity in the Work Space Solution, in, or around the
        building in which Work Space Solution is located.
        <br />
        The User shall be responsible for providing the correct information. In
        case, the information is found false/inaccurate/incomplete and/or not in
        accordance with the terms of usage, BHIVE shall have the right to
        suspend or terminate or block access to the mobile App and also Work
        Space Solution. The User shall indemnify BHIVE for any loss, damage,
        harassment etc. to BHIVE on account of such information.
        <br />
        The User agrees that no action shall lie against BHIVE for any bad debts
        or liabilities of the Company and such debts/ liabilities shall not lead
        to any charge or encumbrance on the Office Space. Further the Company
        agrees that it has no right and shall not create charge and/or take loan
        against the Office Space.
        <br />
        The User understands that it may be required to pay additional fee for
        events and services that are not included in the Work Space Solution
        being utilized by the User. No User shall have the right of usage except
        or beyond the purview of the agreed terms and conditions.
        <br />
        The User shall not maintain or store any inventory of goods in common
        areas of the spaces housing Work Space Solution that have not been
        expressly designed for such purpose without prior written permission of
        BHIVE.
        <br />
        The User shall not engage in any online business activities of a
        questionable moral character or view any objectionable content that may
        damage or otherwise adversely affect the goodwill and/or reputation of
        BHIVE.
        <br />
        BHIVE shall also neither be liable for any theft of or be held
        responsible for any other. Property of the User in any manner, nor for
        injury to persons or loss, damage to property of the User resulting from
        any cause whatsoever, unless due to any willful act of BHIVE. The
        rights/benefits/privileges of the User are non- transferrable in nature
        and the User cannot transfer or let any third party use the same. The
        User agrees that a failure to enforce any provision of this agreement
        and/or terms and conditions governing the use of Work Space Solution by
        the User shall not be deemed a waiver by BHIVE of such provision nor of
        the rights to enforce such provision.
        <br />
        In additions to the rules and policies the User/Company shall follow the
        following rules and obligations while using the Premises:
        <br />
        All the Users of the Premises shall be above the age of 18 years. Any
        person who is below 18 years of age can only transact under the
        registration of his/her legal guardian/parents. BHIVE reserves the right
        to terminate any use or access to Work Space Solution if it is found
        that the User is ineligible to use the Application on account of being a
        minor. Any use or access of a Work Space Solution by anyone under 18
        except under that of his/her legal guardian/parents is unauthorized and
        in violation of these Terms of Use. You agree to use the Site/App only
        for lawful purposes. You are prohibited from posting or transmitting to
        or through the Site/App any unlawful, harmful, threatening, abusive,
        harassing, defamatory, vulgar, obscene, profane, hateful material, or
        any other material that could give rise to civil or criminal liability
        under the law. We may disclose any content or electronic communication
        of any kind (including your profile, e-mail address and other
        information) (1) to satisfy any law, regulation, or government request;
        (2) if the disclosure is necessary or appropriate to operate the Site;
        or (3) to protect the rights or property of BHIVE, its affiliates, and
        you. You must comply with all applicable laws and BHIVE Workspace
        policies, BHIVE Byelaws, including but not limited to the Member
        Handbook. You are solely responsible for safeguarding your personal
        belongings. BHIVE Workspace shall not be held liable for loss or damage
        to personal property. We expect you and your guests to use the Services
        without creating any nuisances or otherwise disturbing other members
        and/or visitors. Phone calls and meetings should be carried out in the
        designated areas. Displaying, affixing, distributing or otherwise making
        evident to other users promotional and advertising materials and signs,
        labels, signage and other similar items are strictly prohibited. Smoking
        in BHIVE Workspace is not allowed. You may smoke only in the designated
        open-air smoking sites or outside the building. Use of drugs and other
        illegal substances as well as alcoholic beverages and/or bringing in any
        hazardous materials and items is strictly forbidden as per BHIVE
        Byelaws. BHIVE reserves the right to limit access for violation of any
        of the Terms or other BHIVE Byelaws.
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={800}
      >
        4. Fees
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        All payments must be made online through our official mobile application
        or website. Accepted online payment methods include major credit cards,
        debit cards, net banking, Unified Payments Interface (UPI), and digital
        wallets offered by our payment gateways. Offline payment modes, such as
        physical swiping of cards, demand drafts, and cheques, are strictly not
        accepted. All payments are prepaid and must be completed using the
        online payment options provided.
        <br />
        All the payments made by you for the Services/ facilities are payable in
        advance. The payments shall be made by either of the accepted modes of
        payment in advance. However, for booking of Work Space Solution beyond
        period of 30 days the payment shall be made at absolute discretion of
        BHIVE. User agrees that all payments are non-refundable and
        non-transferable. Membership activation requires completion of Know Your
        Customer KYC verification. Failure in completion of KYC by BHIVE may
        result in cancellation without refund. Any amount payable by the User
        under this Site or App will be exclusive of local taxes, including, but
        not limited to goods and services tax. If any supply made by BHIVE under
        this Terms of Use or other BHIVE Byelaws is a taxable supply.
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={800}
      >
        5. Guests and visitors
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        You may invite guests and visitors, subject to registration in our
        reservations system to our reception. You shall ensure that all guests
        and visitors shall comply with the present Terms and other BHIVE
        Byelaws. It is a set of guidelines applicable to User and its Guest/
        Visitor under the terms of the Terms. Guest shall mean a person who
        accompanies the User for a continuous duration of 1 hour or more and
        Visitor shall mean a person who visits the User for duration of 1 hour
        or less at the BHIVE Workspace. Common Area shall mean the waiting area
        around the BHIVE reception excluding the areas dedicated for the use by
        Users. Number of person/s permissible as User’s Guest/s shall depend
        upon the type and plan of booking by the User. User shall be responsible
        for booking sufficient meeting room/ conference rooms to entertain its
        Guests at the time of booking and BHIVE shall not be responsible for
        providing any additional space beyond the Common Area. Guests will be
        allowed in Common Area as per availability of space in Common Area
        subject to discretion of BHIVE. If the Guest stays for more than 1 hour
        without sufficient space being booked by the User, the User shall have
        to purchase day pass for its Guest. In the event of sufficient space
        being not booked by User and sufficient space being also not available
        for the purpose of Day Pass, the Guest shall not be allowed to stay at
        the BHIVE premises for more than 1 hour. Guests and Visitors are
        required to adhere to the terms and conditions of BHIVE Byelaws and this
        Term. User shall ensure that its Guests and Visitors carry valid
        Identity Proof with them failing which they shall be denied access to
        BHIVE Workspace. User shall be solely responsible and liable for any
        actions/omission/commission and behaviour of its Guests and Visitors
        during their visit at BHIVE Workspace.
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={800}
      >
        6. Right to disclose information
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        BHIVE shall use, store, and process your personal data collected for the
        purpose of using its services in accordance with applicable laws and
        regulations and as outlined in our{" "}
        <a href={PRIVACY_POLICY}>Privacy Policy</a>. BHIVE shall not be
        responsible, however, for storing and keeping personal data (either
        yours or pertaining to third parties) used by our members, guests,
        visitors and/or stored in their desks, workplace, computers, lockers,
        documents and other personal belongings. BHIVE shall not bear any
        liability for breach or illegal disclosure of such personal data and
        should be indemnified for any damages, costs and liabilities that may
        arise in this respect.
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={800}
      >
        7. Use of Site/App and restrictions
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        This Site/App and its Contents may be used solely for your own personal,
        non-commercial use. Any other use of the Site or the Contents is
        strictly prohibited, including, without limitation, modification,
        removal, deletion, transmission, publication, distribution, proxy
        cashing, uploading, posting, redistribution, re-licensing, selling,
        duplicating, republication or any other dissemination without the
        express written permission of BHIVE. You may not use any framing
        techniques to enclose any trademarks or logos of BHIVE nor use any meta
        tags or other hidden text without our prior written consent. You may not
        link to the Site/App without our prior written consent. Use of spiders,
        robots and similar data gathering and extraction tools is expressly
        prohibited. You may view and print a copy of the Contents displayed on
        the Site, and download a copy of any Contents that is designated for
        downloading, for your personal use only but you may not alter the
        Contents in any way, including, without limitation, removing or
        modifying any copyright or other ownership notices. The rights, title
        and interest in the Contents are not transferred to you by copying or
        downloading the material. The information provided on this Site is free
        of charge and for informational purposes only and does not create a
        business or professional services relationship between you and BHIVE.
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={800}
      >
        8. Confidentiality
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        You, your guests and visitors acknowledge and declare that during their
        visit of BHIVE Workspace and use of BHIVE services may be exposed to
        confidential information pertaining to BHIVE and/or other customers,
        members, guests, visitors or staff. Confidential information shall be
        considered any information that relates to BHIVE, its members,
        customers, guests, visitors, staff which is nonpublic and confidential
        in nature, as well as information about business, sales, operations,
        know-how, products, employees, customers, marketing plans, financial
        information, business affairs, any information obtained though
        examination or access to the facilities, computer systems and/or books
        and records of BHIVE, its customers, members, staff, guests and visitors
        or other information that is known or has reason to be known as
        confidential. You, your visitors and guests of BHIVE Workspace should
        keep above Confidential Information in strict confidence and not
        disclose it to any third parties, nor to use it without the permit of
        the person/s such information belongs to. This obligation is not
        applicable if the disclosure of Confidential Information is required by
        law or by a lawful order of the court or other authority.
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={800}
      >
        9. Disclaimer and Liability
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        To the extent permitted by law, BHIVE provides the services “as is” and
        hereby disclaim with regard to services any warranties and conditions,
        explicit or implied, including but not limited to any warranties or
        duties as to merchantability, fitness for particular purpose, use,
        results or lack of negligence. Further, BHIVE, its directors and staff
        shall not be liable for any indirect and consequential loss or damage,
        including loss of profit, loss of confidential or other information,
        business interruption, personal injury loss of privacy, failure to meet
        any duty arising out of or in any way related to the participation or
        inability to participate in or use the services, of provision of or
        failure to provide the services, or otherwise under or in connection
        with any agreement for use BHIVE services. BHIVE shall be indemnified
        and hold harmless from and against any claims, liabilities, losses,
        damages costs, fines and penalties based upon or arising from illegal,
        negligent actions, errors and omissions or willful misconduct or fraud
        of any of its members, visitors, guests or other third parties resulting
        from a participation, provision or use of any of the services provided
        by BHIVE. BHIVE reserves its right to suspend or terminate any of the
        services hereunder at any time. BHIVE may terminate any membership,
        registration or subscription for use of any of the services provided
        hereunder with immediate effect in case of breach of this Terms. Links
        on this Site/App may lead to services or sites not controlled or
        operated by BHIVE. We provide these links for your convenience and
        information. Links are not an endorsement of the site or service. We
        assume no responsibility or liability for other sites or services. Any
        use you make of any site or service linked to by this Site/App is
        entirely at your own risk.
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={800}
      >
        10. Miscellaneous:
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        <TypographyPointer>
          a. Governing Law: These Terms are governed by the laws of India. Any
          disputes will be subject to the exclusive jurisdiction of the courts
          in Bangalore, Karnataka.
        </TypographyPointer>
        <br />
        <TypographyPointer>
          b. If there is a determination that any provision of these Terms of
          Use is invalid or unenforceable, that determination will not affect
          the rest of these Terms of Use. Regardless of any statute or law to
          the contrary, any claim or cause of action arising out of or related
          to use of this Site/App or under these Terms of Use must be filed
          within one (1) year after such claim or cause of action arose or be
          forever barred. The section titles in these Terms and Conditions are
          for convenience only.
        </TypographyPointer>
        <br />
        <TypographyPointer>
          c. You are responsible for complying with the laws of the jurisdiction
          from which you are accessing this Site/App, and you agree that you
          will not access or use the information on this Site in violation of
          such laws. These Terms of Use and your use of this Site or App will be
          governed by the substantive laws of Republic of India. Our failure to
          enforce any rights under these Terms of Use will not constitute a
          waiver of any terms or conditions of these Terms of Use.
        </TypographyPointer>
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={800}
      >
        Contact Information
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left" }}
        fontWeight={500}
      >
        For any questions or concerns, please contact us:
        <br />
        <BoldSpan>Email:</BoldSpan>{" "}
        <a
          style={{
            color: `${theme.palette.primary.dark}`,
          }}
          href="mailto:sales@bhiveworkspace.com"
        >
          sales@bhiveworkspace.com
        </a>
        <br />
        <BoldSpan>Phone:</BoldSpan> +91 95386 77774
        <br />
        <BoldSpan>Address:</BoldSpan>
        BHIVE Workspace, L148, 5th Main Rd, Sector 6, HSR Layout, Bengaluru,
        Karnataka 560102
      </TypographyParagraph>
      <TypographyParagraph
        variant="h6"
        color={theme.palette.neutrals[6]}
        style={{ marginTop: "15px", textAlign: "left", marginBottom: "30px" }}
        fontWeight={500}
      >
        By using our Services, you acknowledge that you have read, understood,
        and agree to these Terms and Conditions.
      </TypographyParagraph>
    </Wrapper>
  )
}

export default TermsAndConditions
