import { Button, Dialog } from "@mui/material"
import TermsAndConditions from "../../containers/TermsAndConditions"
import { ButtonWrapper, FixedButtonContainer, TermsContainer } from "./styles"

interface ITermsModalProps {
  open: boolean
  onClose: () => void
}

const TermsModal = ({ open, onClose }: ITermsModalProps) => {
  const handleClickConfirm = () => {
    onClose()
  }
  return (
    <Dialog
      open={open}
      aria-labelledby="otp-dialog-title"
      aria-describedby="otp-dialog-description"
      onClose={onClose}
      PaperProps={{ style: { borderRadius: "12px" } }}
    >
      <TermsContainer>
        <TermsAndConditions removeImage />
      </TermsContainer>
      <FixedButtonContainer>
        <ButtonWrapper>
          <Button variant="outlined" color="primary" onClick={onClose}>
            Back
          </Button>
          <Button variant="contained" onClick={handleClickConfirm}>
            ok
          </Button>
        </ButtonWrapper>
      </FixedButtonContainer>
    </Dialog>
  )
}

export default TermsModal
