import { Typography, styled } from "@mui/material"
import { Stack } from "@mui/system"
import theme from "../../theme"
import { HoverEffect } from "../../containers/Layouts/styled"
import { DaypassType } from "../../constants/enums"

export const Card = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 7.46px;
  border: 0.93px solid ${theme.palette.neutrals[4]};
  background: ${theme.palette.background.paper};
  padding: 20px 16px 16px;
  ${theme.palette.secondary.contrastText};
  box-shadow: 0px 1px 8px 0px ${theme.palette.neutrals[4]};
`

export const CardHeader = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const CenterHeading = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  color: ${theme.palette.primary.contrastText};
`

export const Distance = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 44px;
  height: 44px;
  border: 0.4px ${theme.palette.neutrals[4]};
  border-radius: 6px;
  background: ${theme.palette.secondary.main};
  ${HoverEffect}

  div {
    font-size: 12px;
  }
`

export const CardBody = styled("div")`
  margin-top: 22px;
`

export const CenterImage = styled("img")`
  height: 175px;
  border-radius: 5px;
`

export const CenterImagePlaceholder = styled("div")`
  height: 175px;
  border-radius: 6px;
  background: ${theme.palette.secondary.main};
`

type PassWrapperProps = {
  passType: string
}

export const PassWrapper = styled("div")<PassWrapperProps>`
  margin-top: 21px;
  height: 58px;
  width: ${({ passType }) =>
    passType === DaypassType.DAYPASS ? "35%" : "60%"};
  background: ${({ passType }) =>
    passType === DaypassType.DAYPASS
      ? theme?.palette.secondary.main
      : theme?.palette.primary.main};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 7px;
  border: 0.5px solid
    ${({ passType }) =>
      passType === DaypassType.DAYPASS
        ? theme?.palette.neutrals[5]
        : theme?.palette.primary.dark};
  border-radius: 5px;
  position: relative;
  ${HoverEffect}
  box-shadow: ${({ passType }) =>
    passType !== DaypassType.DAYPASS
      ? `0px 2px 4px 0px ${theme?.palette.neutrals[4]}`
      : "none"};

  img {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
`

export const PassText = styled(Typography)<PassWrapperProps>`
  font-size: 12px;
  font-weight: 500;
  color: ${({ passType }) =>
    passType === DaypassType.DAYPASS
      ? theme?.palette.text.secondary
      : theme?.palette.text.secondary};
`

export const PassPrice = styled(Typography)<PassWrapperProps>`
  font-size: 18px;
  font-weight: 600;

  s {
    font-size: 10px;
    font-weight: 500;
    color: ${theme?.palette.text.secondary};
  }
  span {
    font-size: 12px;
    font-weight: 500;
    color: ${({ passType }) =>
      passType === DaypassType.DAYPASS
        ? theme?.palette.text.secondary
        : theme?.palette.text.secondary};
  }
`

export const DiscountPercentage = styled("div")`
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: max-content;
  background: ${theme?.palette.primary.contrastText};
  border-radius: 3px;
  padding: 4px 6px;

  span {
    font-weight: 500;
    font-size: 8px;
    color: ${theme?.palette.background.paper};
  }
`
