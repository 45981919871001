import { Dialog } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { BackButton, CloseIconButton } from "../OTPModal/styles"
import FeaturedIcon from "../../assets/FeaturedIcon.svg"
import {
  ErrorModalActionButtonContainer,
  ErrorModalContainer,
  ErrorModalDescription,
  ErrorModalHeader,
  ErrorModalIconContainer,
} from "./style"
import { URLParams } from "../../constants/enums"
import { useSearchParams } from "react-router-dom"
import { ConfirmButton } from "../../containers/Booking/StepperContent/styles"

interface IErrorProps {
  open: boolean
  onClose: () => void
}

const ErrorModal = ({ open, onClose }: IErrorProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Dialog
      open={open}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
      onClose={onClose}
      PaperProps={{ style: { borderRadius: "12px" } }}
    >
      <ErrorModalContainer>
        <CloseIconButton onClick={onClose}>
          <CloseIcon />
        </CloseIconButton>
        <ErrorModalIconContainer>
          <img src={FeaturedIcon} height="48px" alt="ErrorIcon" />
        </ErrorModalIconContainer>
        <ErrorModalHeader>Selection Error</ErrorModalHeader>
        <ErrorModalDescription>
          Since you're booking for someone else, your number can't be used. Go
          back and select 'Booking for yourself'
        </ErrorModalDescription>
        <ErrorModalActionButtonContainer>
          <BackButton variant="outlined" onClick={onClose}>
            Cancel
          </BackButton>
          <ConfirmButton
            sx={{ textTransform: "capitalize" }}
            variant="contained"
            onClick={() =>
              setSearchParams((params) => {
                params.set(URLParams.ACTIVE_STATE, "1")
                return params
              })
            }
          >
            Go Back
          </ConfirmButton>
        </ErrorModalActionButtonContainer>
      </ErrorModalContainer>
    </Dialog>
  )
}

export default ErrorModal
