import { Typography, styled } from "@mui/material"
import HeroDesktop from "../../assets/HeroDesktop.svg"
import HeroMobile from "../../assets/HeroMobile.svg"
import theme from "../../theme"

export const ContentSection = styled("div")`
  margin: 0 20px;
  width: 100%;
  max-width: 1200px;
`

export const HeroSection = styled("div")`
  width: 100%;
  background: url(${HeroDesktop});
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  position: relative;

  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    height: 320px;
  }

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    background: url(${HeroMobile});
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`

export const HeroTitle = styled(Typography)`
  width: 700px;
  font-size: 58px;
  font-weight: 700;
  padding: 80px 0;
  color: ${theme.palette.primary.contrastText};
  & span {
    color: ${theme.palette.text.primary};
  }

  @media (max-width: ${theme.breakpoints.values["lg"]}px) {
    font-size: 50px;
    left: 20px;
  }
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    width: 400px;
    font-size: 30px;
  }
  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    display: none;
  }
`

export const HeroTitleMobile = styled(Typography)`
  display: none;
  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    width: 350px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: auto;
    color: ${theme.palette.primary.contrastText};
    & span {
      color: ${theme.palette.text.primary};
    }
    display: block;
  }
`

export const FacilityHeading = styled(Typography)`
  margin-top: 66px;
  font-size: 36px;
  font-weight: 700;
  color: ${theme.palette.primary.contrastText};
  @media (max-width: ${theme.breakpoints.values["xl"]}px) {
    text-align: center;
  }
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    font-size: 24px;
  }
`

export const FacilityGrid = styled("div")`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0;

  @media (max-width: ${theme.breakpoints.values["lg"]}px) {
    grid-template-columns: repeat(2, 1fr);
    width: 850px;
    margin: 40px auto;
  }
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    margin-top: 30px;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    width: unset;
  }
`

export const FacilityItem = styled("div")`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;
  padding: 24px;
  height: 80px;
  transition: transform 0.3s ease;

  :hover {
    background: ${theme.palette.background.paper};
    transform: translate(5px, -5px);
  }

  &:nth-child(-n + 4) {
    border-bottom: 0.5px solid ${theme.palette.neutrals[4]};
  }
  &:not(:nth-child(4)):not(:nth-child(8)) {
    border-right: 0.6px solid ${theme.palette.neutrals[4]};
  }

  @media (max-width: ${theme.breakpoints.values["lg"]}px) {
    &:not(:nth-child(7)):not(:nth-child(8)) {
      border-bottom: 0.5px solid ${theme.palette.neutrals[4]};
    }
    &:nth-child(even) {
      border-right: none !important;
    }
  }
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    flex-direction: column;
    background: ${theme.palette.background.paper};
    box-shadow: 0px 2px 4px 0px ${theme.palette.neutrals[4]};
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 0px 12px 0px;
    height: 80px;

    &:nth-child(-n + 4) {
      border-bottom: none;
    }
    &:not(:nth-child(4)):not(:nth-child(8)) {
      border-right: none;
    }
  }
`

export const FacilityText = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  color: ${theme.palette.primary.contrastText};
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    font-size: 14px;
  }
`

export const OurSpaceHeading = styled(Typography)`
  margin: 36px 0px 40px;
  font-size: 36px;
  font-weight: 700;
  color: ${theme.palette.primary.contrastText};

  @media (max-width: ${theme.breakpoints.values["xl"]}px) {
    text-align: center;
  }
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    margin-top: 50px;
    font-size: 24px;
  }
`

export const CenterGrid = styled("div")`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(320px, 370px));
  gap: 45px;
  justify-content: center;

  @media (max-width: ${theme.breakpoints.values["lg"]}px) {
    grid-template-columns: repeat(2, minmax(320px, 370px));
  }
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    grid-template-columns: repeat(1, minmax(320px, 370px));
  }
  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    gap: 16px;
  }
`

export const PlaceholderCard = styled("div")`
  width: 100%;
  max-width: 370px;
  min-width: 320px;
  height: 452px;
  border-radius: 4px;
  background: ${theme.palette.secondary.main};
`

export const DownloadAppHeading = styled(Typography)`
  font-weight: 700;
  font-size: 36px;
  margin-top: 120px;
  color: ${theme.palette.primary.contrastText};

  @media (max-width: ${theme.breakpoints.values["lg"]}px) {
    text-align: center;
  }
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    margin-top: 50px;
    font-size: 24px;
  }
`

export const DownloadAppSection = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: end;
  border-radius: 1rem;
  background: ${theme.palette.background.paper};
  padding: 70px 40px 27px 0;
  margin: 200px 0px 40px;
  box-shadow: 0px 1px 8px 0px ${theme.palette.neutrals[4]};
  position: relative;

  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    margin-top: 18px;
    height: 392px;
    padding: 27px 12px 12px;
    align-items: baseline;
    justify-content: flex-end;
  }
`

export const DownloadAppImage = styled("img")`
  position: absolute;
  left: 40px;
  bottom: 0px;
  position: absolute;

  @media (max-width: ${theme.breakpoints.values["lg"]}px) {
    left: 10px;
  }
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    height: 300px;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
  }
`

export const DownloadAppText = styled(Typography)`
  width: 400px;
  font-size: 20px;
  font-weight: 500;
  color: ${theme.palette.text.secondary};

  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    display: none;
  }
`

export const DownloadAppButtons = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 400px;
  margin-top: 40px;
  cursor: pointer;
  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    width: 100%;
    justify-content: center;
  }
`
