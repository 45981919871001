import { styled } from "@mui/material"
import theme from "../../theme"

export const Wrapper = styled("div")`
  width: 100%;
  max-width: 1200px;
  line-height: 24px;
  padding-left: 40px;
  padding-right: 40px;
  margin: 0 auto;
  a {
    color: ${theme.palette.primary.dark};
  }

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;
  }
`

export const StyledGreyParagraph = styled("p")`
  color: ${theme.palette.neutrals[6]};
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`

export const StrongText = styled("p")`
  display: inline;
  width: max-content;
  color: ${theme.palette.neutrals[6]};
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`
