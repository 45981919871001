import { OutlinedInput, styled } from "@mui/material"

export const CustomOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main,
    boxShadow: `0px 0px 0px 4px  ${theme.palette.neutrals[11]}`,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.main,
    boxShadow: `0px 0px 0px 4px ${theme.palette.neutrals[11]}`,
  },
  "&.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.neutrals[9], // Error state border color
    // Add shadow
    boxShadow: `0px 0px 0px 4px ${theme.palette.neutrals[10]}`,
  },
  "&.Mui-disabled": {
    background: theme.palette.neutrals[4],
    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.neutrals[4]}`,
      boxShadow: "none",
    },
  },
}))
