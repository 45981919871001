import { Stepper, Typography, styled } from "@mui/material"
import theme from "../../theme"

// Define props for the styled component
interface StyledStepperIconProps {
  completed: boolean
  active: boolean
}

export const StepperWrapper = styled("div")`
  width: 100%;
  overflow: hidden;
  .css-3qwhp6-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: ${theme.palette.success.main};
  }
`

// Custom Styled Step Icon
export const StyledStepperIcon = styled("div")<StyledStepperIconProps>(
  ({ theme, completed, active }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 25,
    height: 25,
    borderRadius: "50%",
    border: `1px solid ${
      completed
        ? theme.palette.success.main
        : active
        ? theme.palette.primary.main
        : theme.palette.neutrals[8]
    }`,
    backgroundColor: completed
      ? theme.palette.success.main
      : active
      ? theme.palette.primary.main
      : "transparent",
    color: completed
      ? theme.palette.background.paper
      : active
      ? theme.palette.background.paper
      : theme.palette.neutrals[8],
  }),
)

export const StyledStepper = styled(Stepper)``

export const StepperText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  color: ${theme.palette.neutrals[8]};
  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    font-size: 10px;
    font-weight: 500;
  }
`

export const BoldStepperText = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    font-size: 12px;
    font-weight: 600;
  }
`
