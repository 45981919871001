export class Center {
  centerId = ""
  centerName = ""
  centerAddress = ""
  googleMapURL: string | null = null
  daypassPrice = 0
  centerImages: string[] = []
  discountsPercentage = 0
  googleMapsThumbnailURL: string | null = null

  constructor(center?: any) {
    if (center) {
      this.centerId = center.id
      this.centerName = center.name
      this.centerAddress = center.address
      this.googleMapURL = center.google_maps_url
      this.daypassPrice = center.day_pass_price
      this.centerImages = center.images
      this.discountsPercentage =
        center?.day_pass_discounts_percentage?.[10]?.value
      this.googleMapsThumbnailURL = center.google_maps_thumbnail_url
    }
  }
}
