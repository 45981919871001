import { styled } from "@mui/material"
import theme from "../../theme"

export const Wrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background: ${theme.palette.primary.contrastText};
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: ${theme.palette.neutrals[6]};

  @media (max-width: ${theme.breakpoints.values["md"]}px) {
    font-size: 12px;
    padding: 10px 4px;
  }
  p {
    margin: 0;
  }
  span {
    color: ${theme.palette.primary.main};
  }
  a {
    color: ${theme.palette.neutrals[6]};
    text-decoration: none;
  }
`
