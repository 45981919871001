import { styled, Typography } from "@mui/material"
import theme from "../../theme"
import Banner from "../../assets/Banner.jpg"

export const Wrapper = styled("div")`
  width: 100%;
  height: 380px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  padding: 20px;

  background: url(${Banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export const StyledText = styled(Typography)`
  color: ${theme.palette.primary.dark};
  text-align: center;
  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    font-size: 28px;
  }
`
